import * as React from "react"
import { Container } from "reactstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <section>
            <Container>
                <h1>404: Not Found</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </Container>
        </section>
    </Layout>
)

export default NotFoundPage
